import React from "react";
import html from  "../assets/experience/html.png"
import css from  "../assets/experience/css.png"
import js from  "../assets/experience/javascript.png"
import reactimg from  "../assets/experience/react.png"
import java from  "../assets/experience/java.png"
import php from  "../assets/experience/php.png"
import github from  "../assets/experience/github.png"
import tailwind from  "../assets/experience/tailwind.png"
import node from "../assets/experience/node.png";
import csharp from "../assets/experience/csharp.png"
import sql from "../assets/experience/sql.png"

const Experience = () => {
const techs = [
    {
        id: 1,
        src: html,
        title: 'HTML',
        style: 'shadow-orange-500'
    },
    {
        id: 2,
        src: css,
        title: 'CSS',
        style: 'shadow-blue-500'
    },
    {
        id: 3,
        src: js,
        title: 'JavaScript',
        style: 'shadow-yellow-500'
    },
    {
        id: 4,
        src: reactimg,
        title: 'React',
        style: 'shadow-blue-600'
    },
    {
        id: 5,
        src: tailwind,
        title: 'Tailwind',
        style: 'shadow-sky-400'
    },
    {
        id: 6,
        src: java,
        title: 'Java',
        style: 'shadow-red-800'
    },
    {
        id: 7,
        src: php,
        title: 'PHP',
        style: 'shadow-purple-300'
    },
    {
        id: 8,
        src: github,
        title: 'GitHub',
        style: 'shadow-gray-400'
    },
    {
        id: 8,
        src: node,
        title: 'Node.js',
        style: 'shadow-green-400'
    },
    {
        id: 8,
        src: csharp,
        title: 'C#',
        style: 'shadow-purple-400'
    },
    {
        id: 8,
        src: sql,
        title: 'SQL',
        style: 'shadow-orange-300',
        spacing: 'mb-14'
    },
]



    return (


        <div
            name="experience"
            className="bg-gradient-to-b from-gray-800 to-black w-full md:h-max"
        >
            <div className="max-w-screen-lg mx-auto p-4 flex flex-col justify-center w-full h-full text-white">
                <div>
                    <p className="text-4xl font-bold border-b-4 border-gray-500 p-2 inline">Experience
                    </p>
                    <p className="py-6">These are the technologies I've worked with</p>
                </div>
                <div className="w-full grid grid-cols-2 sm:grid-cols-3 gap-8 text-center py-8 px-12 sm:px-0">

                    {
                        techs.map(({id, src, title, style, spacing}) => (
                            <div key={id} className={`shadow-md mb hover:scale-105 duration-500 py-2 rounded-lg ${style}`}>
                                <img src={src} alt="" className={`w-20 mx-auto ${spacing}`}/>
                                
                                <p className="mt-4">{title}</p>
                            </div>

                        ))}




                </div>
            </div>
        </div>

    );
}

export default Experience;

import React from "react";
import arrayList from "../assets/portfolio/arrayList.png";
import javaFX from "../assets/portfolio/javaFX.PNG";
import leetCode from  "../assets/portfolio/leetCode.png";
import fyp from "../assets/portfolio/fyp.PNG";
import db from "../assets/portfolio/discord.png"

const Portfolio = () => {
    
    const portfolios  = [
        {
            id:1, 
            src: arrayList,
            link: "https://github.com/khubaybk/ToDoList",
            text: "The project aimed to demonstrate my understanding of data structures and algorithms. I chose to implement the Array List data structure for storing and managing the tasks in the to-do list application."
        },
        {
            id:2,
            src: javaFX ,
            link: "https://github.com/khubaybk/JavaFX-educational-game",
            text: "\n" +
                "An engaging JavaFX game intertwines MVC architecture and the Singleton pattern to create a captivating scientific space invader experience. Players defend Earth using scientific tools, while MVC ensures efficient data separation. Singleton ensures a single control center, resulting in an immersive, organized, and challenging gaming adventure."
            
        },
        {
            id:3,
            src: leetCode,
            link: "https://github.com/khubaybk/LeetCodeDSA",
            text: "Engaging with LeetCode questions has significantly honed my problem-solving prowess. Through consistent practice, I've cultivated a strategic approach to dissecting complex challenges, identifying core patterns, and devising efficient algorithms. This iterative process has augmented my coding skills, enhancing my capacity to craft elegant and optimized solutions"
        },
        {
            id:4,
            src: fyp,
            link: "https://github.com/khubaybk/FinalYearProject",
            text: "This MERN stack social media app prioritizes mental well-being by automatically logging users out after a set time. Offering seamless connectivity, it blends MongoDB, Express, React, and Node.js. Users engage, connect, and share while ensuring healthy online habits and fostering a balanced digital lifestyle."
        },
        {
            id:5,
            src: db,
            link: "https://github.com/khubaybk/discordCODBot",
            text: "A Java-powered Discord bot fetches Call of Duty: Warzone stats swiftly. Seamlessly integrated into Discord, it uses API calls to provide real-time player statistics, including kills, wins, and K/D ratios. Elevating the gaming experience, it keeps players informed and engaged with their in-game performance."
        },
    ]
      
    
    
    
    return(
        <div
            name="portfolio"
            className="bg-gradient-to-b from-black to-gray-800 w-full text-white md:h-max "
        >
            <div className="max-w-screen-lg p-4 mx-auto flex flex-col justify-center w-full h-full">
                <div className="pb-8">
                    <p className="text-4xl font-bold inline border-b-4 border-gray-500">
                        Portfolio
                    </p>
                    <p className="py-6">
                        Check out some of my work below:
                    </p>
                    <div  className="grid sm:grid-cols-2 md:grid-cols-3 gap-8 px-12 sm:px-0">
                        {portfolios.map(({id, src, text, link}) => (
                            <div key={id} className="shadow-md shadow-gray-600">
                                <img src={src} alt=""  className="rounded-md duration-200 hover:scale-105"/>
                                <div className="flex items-center justify-center">
                                    <form action={link}>
                                        <button  className="w-1/2 px-6 py-3 m-4 duration-200 hover:scale-105">Code</button>
                                    </form>
                                </div>
                                <p  className="p-3 text-center " >{text}</p>

                            </div>
                        )) }

                    </div>

                </div>


            </div>



        </div>
    )
}

export default Portfolio;
//style={{maxHeight: 130, width: '100%'}}